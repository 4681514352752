import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { antiFraudsService } from "@api/services";

export const useGetReRegistrationFraudUsers = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.ANTI_FRAUDS_RE_REGISTRATION_USERS],
    queryFn: async () => await antiFraudsService.getReRegistrationUsers(),
  });
};
