import { httpClient } from "@common/Libs/axios";
import { IDType, TariffType } from "@common/Types";
import { commaSerializer } from "@common/Utils";

import { tariffsPath } from "./paths";

/**
 * Описание методов tariffs.
 */

export const tariffsService = {
  getTariffs: () => httpClient.get<TariffType[]>(tariffsPath.tariffs),
  getTariffsById: (id: IDType) =>
    httpClient.get<TariffType[]>(tariffsPath.tariffsById, {
      params: {
        city_id: id,
      },
      paramsSerializer: commaSerializer,
    }),
  updateTariff: (tariff: TariffType) => {
    const { id, ...rest } = tariff;

    return httpClient.patch(tariffsPath.updateTariff(id), rest);
  },
};
