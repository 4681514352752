import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { permissionsService } from "@api/services";
import { AccessPages } from "@common/Types";

export const usePostAdminAccess = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (accessList: Omit<AccessPages, "name">[]) =>
      await permissionsService.postAccess(accessList),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ACCESSES] });

      toast.success("Доступ успешно обновлeн");
    },
    retry: 0,
  });
};
