import React, { ReactNode } from "react";

import { SvgIconComponentType } from "@common/Types";
import { Flexbox, Row, Typography } from "@components/Elements";

type Props = {
  children: ReactNode;
  title?: string;
  Icon?: SvgIconComponentType;
  renderRowItem?: JSX.Element;
  renderRightItem?: JSX.Element;
};

export const Block = ({
  children,
  Icon,
  title,
  renderRowItem,
  renderRightItem,
}: Props) => {
  return (
    <div className="shadow-md p-4 bg-neutral-bg-0 space-y-4 rounded-lg divide-y divide-neutral-border-100">
      {title && (
        <Flexbox
          justifyContent="justify-between"
          alignItems="items-center"
          className="gap-2"
        >
          <Row alignItems="items-center" className="gap-4 flex-wrap">
            {Icon && <Icon />}

            <Typography
              color="neutral-800"
              variant="heading-2-b"
              as="h2"
              className="font-semibold"
            >
              {title}
            </Typography>

            {renderRowItem}
          </Row>

          {renderRightItem}
        </Flexbox>
      )}

      {children}
    </div>
  );
};
